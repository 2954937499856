/* eslint-disable @typescript-eslint/no-explicit-any */
import type { QueryFunctionContext } from "react-query";

import { ensureArray } from "./ensureArray";

import { buildPath, defaultHost } from "src/utilities/api";

interface IQueryKey {
  query?: Record<string, string | string[]>;
  fragment?: string;
  host?: string;
}

interface IDefaultQueryFnProps {
  accessToken?: string;
  responseCb?: (response: Response) => void;
  headers?: Record<string, string>;
}

function isQueryObject(input: unknown): input is IQueryKey {
  return typeof input === "object";
}

export function defaultQueryFn({
  accessToken,
  responseCb,
  headers = {},
}: IDefaultQueryFnProps) {
  return async function queryFn({
    queryKey: [path, config],
  }: QueryFunctionContext): Promise<unknown> {
    const host = (isQueryObject(config) && config.host) || defaultHost;

    const url = new URL(host);
    const [pathname, search] = (path as string).split("?");

    url.pathname = buildPath(pathname, host);

    if (search) {
      url.search = search;
    }

    if (isQueryObject(config)) {
      if (config.query != null) {
        Object.entries(config.query).forEach(([key, values]) => {
          ensureArray<string>(values).forEach((value) => {
            url.searchParams.append(key, value);
          });
        });
      }

      if (config.fragment != null) {
        url.hash = `#${config.fragment}`;
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { host: _host, ...passHeaders } = headers;

    const req = new Request(url.toString(), {
      credentials: "include",
      headers: passHeaders,
    });

    if (accessToken) {
      req.headers.append("authorization", `Bearer ${accessToken}`);
    }

    const response = await fetch(req);

    if (!response.ok) {
      const { message, code } = await response.json();
      throw new Error(code || message);
    }

    responseCb?.(response);

    return response.json();
  };
}
