import loadable from "@loadable/component";
import { LoadableComponent } from "next/dynamic";

const BusinessSettingsLayout = loadable(
  () => import("./Settings/BusinessSettings")
);
const UserSettingsLayout = loadable(() => import("./Settings/UserSettings"));
const ApplicationFormLayout = loadable(() => import("./ApplicationForm"));

export enum ESectionLayout {
  BusinessSettings,
  UserSettings,
  ApplicationForm,
}

export function getLayout(
  layout: ESectionLayout
): LoadableComponent<unknown> | "div" {
  switch (layout) {
    case ESectionLayout.BusinessSettings:
      return BusinessSettingsLayout as LoadableComponent<unknown>;
    case ESectionLayout.UserSettings:
      return UserSettingsLayout as LoadableComponent<unknown>;
    case ESectionLayout.ApplicationForm:
      return ApplicationFormLayout as LoadableComponent<unknown>;
    default:
      return "div";
  }
}
