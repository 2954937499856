export const isLocal: boolean = process.env.REACT_APP_ENV === "local";

export const defaultHost: string = (
  isLocal ? process.env.REACT_APP_URL : process.env.REACT_APP_GATEWAY_URL
) as string;

export function buildPath(path: string, host: string): string {
  if (!isLocal || host !== process.env.REACT_APP_URL) {
    return path;
  }

  if (path.startsWith("api") || path.startsWith("/api")) {
    return path;
  }

  if (path.startsWith("/")) {
    return `/api${path}`;
  }

  return `/api/${path}`;
}
