import type { MutationFunction } from "react-query";

import { defaultHost, buildPath } from "./api";
import { sanitizeRequestBody } from "./sanitizeRequestBody";

export function mutateToPath<TData, TVariables>(
  path: string,
  {
    host = defaultHost,
    accessToken,
    method = "POST",
    sanitize = true,
  }: {
    accessToken?: string;
    host?: string;
    method?: "POST" | "PATCH" | "PUT";
    sanitize?: boolean;
  } = {}
): MutationFunction<TData, TVariables> {
  return async function mutation(body: TVariables) {
    const url = new URL(host);

    url.pathname = buildPath(path, host);

    const req = new Request(url.toString(), {
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
    });

    if (accessToken) {
      req.headers.append("authorization", `Bearer ${accessToken}`);
    }

    const response = await fetch(req, {
      method,
      body: JSON.stringify(sanitize ? sanitizeRequestBody(body) : body),
      credentials: "include",
    });

    if (!response.ok) {
      const { message, code } = await response.json();

      throw new Error(code || message);
    }

    switch (response.headers.get("content-type")?.split(";")[0]) {
      case "application/json":
        return response.json();
      default:
        return response.text();
    }
  };
}
