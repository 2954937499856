import jwtDecode from "jwt-decode";
import { createContext, ReactNode, useContext, useEffect } from "react";
import { useRouter } from "next/router";

import { useAccessTokenMutation } from "src/hooks/queries/useAccessToken";
import { useRefreshAccessToken } from "src/hooks/queries/useRefreshAccessToken";

interface IAccessContext {
  accessToken?: string;
}

export const AccessContext = createContext<IAccessContext>({
  accessToken: undefined,
});

interface IAccessProviderProps {
  accessToken: string;
  children: ReactNode;
  setAccessToken: (jwt: string) => void;
}

export function AccessProvider({
  accessToken,
  children,
  setAccessToken,
}: IAccessProviderProps): JSX.Element {
  const { push, pathname } = useRouter();
  const refreshAccessToken = useRefreshAccessToken();
  const accessTokenMutation = useAccessTokenMutation();

  let expiryTimeout = Number(process.env.VISION_API_TIMEOUT); // default value is 15 mins set from the BE, but can change when accesstoken is received.

  const getExpiryTimeout = (token: string): number => {
    const { exp }: { exp: number } = jwtDecode(token);
    return exp * 1000 - new Date().getTime() - 5 * 1000;
  };

  if (accessToken) {
    expiryTimeout = getExpiryTimeout(accessToken);
  }

  useEffect(() => {
    const timeout = setTimeout(async () => {
      try {
        if (accessToken) {
          const refreshTokenResponse = await refreshAccessToken();
          await accessTokenMutation(refreshTokenResponse.jwt);
          setAccessToken(refreshTokenResponse.jwt);
        }
      } catch {
        push(`/resignin?href=${pathname}`);
      }
    }, expiryTimeout);
    return () => clearTimeout(timeout);
  }, [
    accessToken,
    refreshAccessToken,
    accessTokenMutation,
    expiryTimeout,
    pathname,
    push,
    setAccessToken,
  ]);

  return (
    <AccessContext.Provider value={{ accessToken }}>
      {children}
    </AccessContext.Provider>
  );
}

export function useAccess(): IAccessContext {
  return useContext(AccessContext);
}
