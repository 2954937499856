import { createTheme, alpha } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import createPalette from "@material-ui/core/styles/createPalette";
import loadable from "@loadable/component";

import { EIcon, Icon } from "./components/Icon";

const DataGridPagination = loadable(
  () => import("src/components/DataGridPagination")
);

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 680,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
});

const palette = createPalette({
  background: {
    default: "#ffffff",
  },
  common: {
    black: "#222",
    white: "#fff",
  },
  primary: {
    main: "#ff591f",
    light: "#ff3f41",
  },
  error: {
    main: "#ff3f41",
  },
  success: {
    main: "#15cc2d",
  },
  secondary: {
    main: "#c193b7",
  },
  text: {
    primary: "#222222",
    secondary: "#767676",
    disabled: "#BBBBBB",
    hint: "#555555",
  },
  tillGrey: {
    dark: "#555555",
    main: "#767676",
    light: "#BBBBBB",
    contrastText: "#FFFFFF",
  },
  divider: "#EEEEEE",
});

export const solidBorder = `1px solid ${palette.divider}`;
export const boxShadow = `0 0 0.5rem 0 ${alpha(palette.common.black, 0.15)}`;

const spacing = (factor: number) => `${0.625 * factor}rem`;

const theme = createTheme({
  breakpoints,
  palette,
  spacing,
  shape: {
    borderRadius: 6,
  },
  typography: {
    allVariants: {
      letterSpacing: 0,
    },
    h1: {
      fontSize: "1.375rem",
      fontWeight: "initial",
    },
    h2: {
      fontSize: "1.25rem",
      fontWeight: "initial",
    },
    h3: {
      fontSize: "1.125rem",
      fontWeight: "initial",
    },
    body1: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "0.875rem",
    },
    subtitle1: {
      fontSize: "0.875rem",
      fontWeight: "initial",
      color: palette.text.secondary,
      letterSpacing: "0em",
      lineHeight: "1.25rem",
    },
  },
  props: {
    MuiChip: {
      variant: "outlined",
      size: "small",
    },
    MuiCard: {
      elevation: 0,
      variant: "outlined",
    },
    MuiCheckbox: {
      color: "primary",
      icon: <Icon icon={EIcon.CHECKBOX} />,
      checkedIcon: <Icon icon={EIcon.CHECKBOX_FILLED} />,
    },
    MuiDialog: {
      scroll: "body",
    },
    MuiRadio: {
      color: "primary",
    },
    MuiDataGrid: {
      autoHeight: true,
      disableColumnFilter: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      disableSelectionOnClick: true,
      disableDensitySelector: true,
      disableExtendRowFullWidth: true,
      components: {
        Pagination: DataGridPagination,
      },
    },

    // This section is for Lab components.
    // These are components that have not made it into the styles API as yet.

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    MuiPagination: {
      color: "primary",
      variant: "outlined",
      shape: "rounded",
      hidePrevButton: true,
      hideNextButton: true,
    },
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        dl: {
          margin: 0,
        },
        dt: {
          margin: 0,
        },
        dd: {
          margin: 0,
        },
      },
    },
    MuiChip: {
      root: {
        borderRadius: "2px",
        "&$sizeSmall": {
          height: "1.1875rem",
        },
      },
      label: {
        fontSize: "0.625rem",
        textTransform: "uppercase",
        fontWeight: "bold",
      },
    },
    MuiPaper: {
      root: {
        maxWidth: "100%",
      },
    },
    MuiCardContent: {
      root: {
        [breakpoints.up("sm")]: {
          padding: `${spacing(2)} ${spacing(3)}`,
          "&:last-child": {
            paddingBottom: null,
          },
        },
        padding: spacing(2),
        "&:last-child": {
          paddingBottom: null,
        },
      },
    },
    MuiBreadcrumbs: {
      root: {
        "& *": {
          fontSize: "0.65rem",
          fontWeight: 500,
          color: palette.text.secondary,
        },
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: "0.375rem",
        [breakpoints.down("xs")]: {
          margin: spacing(2),
          "&.MuiDialog-paperFullWidth": {
            width: `calc(100% - ${spacing(4)})`,
          },
          "&.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody": {
            maxWidth: `calc(100% - ${spacing(4)})`,
          },
        },
      },
    },
    MuiDialogTitle: {
      root: {
        borderBottom: "1px solid #eee",
        [breakpoints.up("sm")]: {
          paddingLeft: spacing(3),
          paddingRight: spacing(3),
        },
      },
    },
    MuiDialogContent: {
      root: {
        "&:first-child": {
          paddingTop: spacing(2),
        },
        [breakpoints.up("sm")]: {
          padding: spacing(3),
        },
        padding: spacing(2),
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(0,0,0,0.75)",
      },
    },
    MuiListItem: {
      root: {
        paddingTop: `calc(${spacing(1)} / 2)`,
        paddingBottom: `calc(${spacing(1)} / 2)`,
      },
    },
    MuiListItemIcon: {
      root: {
        height: "1.125rem",
        minWidth: "2rem",
      },
    },
    MuiIcon: {
      root: {
        width: "1.125rem",
        height: "1.125rem",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "2px",
        "&$disabled": {
          backgroundColor: palette.tillGrey.light,
          color: palette.text.primary,
        },
        "&$focused $notchedOutline": {
          borderWidth: 1,
        },
      },
      input: {
        padding: `${spacing(1.6)} ${spacing(2)}`,
      },
      notchedOutline: { borderColor: palette.divider },
    },
    MuiButton: {
      root: {
        fontWeight: "bold",
        textTransform: undefined,
        fontSize: "1rem",
        letterSpacing: "0rem",
        "&$disabled": {
          backgroundColor: `${palette.tillGrey.light} !important`,
        },
      },
    },
    MuiLink: {
      root: {
        "&:focus-visible": {
          outline: "none",
          textDecoration: "underline",
        },
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: "none",
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: "0.875rem",
      },
    },
    MuiFormLabel: {
      root: {
        color: palette.text.primary,
      },
    },
    MuiDataGrid: {
      root: {
        "& .MuiDataGrid-cell, .MuiDataGrid-columnHeader": {
          padding: `0 ${spacing(3)}`,
        },
        "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within":
          {
            outline: "none",
          },
        "& .MuiDataGrid-columnSeparator": {
          display: "none",
        },
        "& .MuiDataGrid-columnHeaderTitleContainer": {
          padding: 0,
          "& .MuiDataGrid-columnHeaderTitle": {
            color: palette.text.secondary,
            fontWeight: "bold",
            fontSize: "0.75rem",
          },
        },

        "& .MuiDataGrid-columnHeader": {
          textTransform: "uppercase",
        },

        "& .MuiDataGrid-main": {
          borderRadius: "5px",
          backgroundColor: palette.common.white,
          borderColor: palette.divider,
          borderStyle: "solid",
          borderWidth: "1px",
          overflow: "hidden",
        },
        "& .MuiDataGrid-dataContainer .MuiDataGrid-cell, .MuiDataGrid-columnHeader":
          {
            borderBottom: 0,
          },
        "& .MuiDataGrid-columnHeaderWrapper": {
          borderBottom: solidBorder,
        },

        "& .MuiDataGrid-dataContainer .MuiDataGrid-row": {
          position: "relative",
        },
        "& .MuiDataGrid-dataContainer .MuiDataGrid-row::after": {
          content: '""',
          width: `calc(100% - 4rem)`,
          height: "1px",
          background: `repeating-linear-gradient(270deg, ${palette.divider}, ${palette.divider} 1px, transparent 1px, transparent 4px)`,
          position: "absolute",
          bottom: 0,
          left: `2rem`,
        },
        "& .MuiDataGrid-dataContainer .MuiDataGrid-row:last-child::after": {
          content: "none",
        },
      },
    },
    MuiTabs: {
      root: {
        borderBottom: solidBorder,
        marginBottom: spacing(2),
      },
      indicator: {
        backgroundColor: palette.primary.main,
        height: "3px",
      },
    },
    MuiTab: {
      root: {
        textTransform: "capitalize",
        minWidth: "0 !important",
        padding: "0",
        marginRight: spacing(2),
        "&:last-child": {
          marginRight: 0,
        },
        "&:hover, &:focus-within": {
          borderBottomColor: palette.primary.main,
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          marginBottom: "-1px",
          paddingBottom: "0",
        },
      },
    },
    MuiMenuItem: {
      root: {
        borderBottom: solidBorder,
        "&:last-child": {
          borderBottom: "none",
        },
      },
    },
    MuiSelect: {
      outlined: {
        "&.MuiSelect-outlined": {
          borderRadius: "2px",

          paddingRight: "2.5rem",
        },
        "&.MuiSelect-outlined.MuiOutlinedInput-inputAdornedStart": {
          paddingLeft: "0.875rem",
          marginLeft: "-0.875rem",
        },
      },
      iconOutlined: {
        right: "1.25rem",
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: palette.background.default,
        padding: `${spacing(1)} ${spacing(2)}`,
        color: palette.text.hint,
        fontSize: "0.875rem",
        fontWeight: "normal",
        boxShadow,
        borderRadius: 2,
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: palette.divider,
        "&$checked": {
          color: palette.primary.main,
        },
      },
    },
    MuiSvgIcon: {
      colorSecondary: {
        color: palette.divider,
      },
    },
    MuiFormControlLabel: {
      root: {
        color: palette.text.hint,
        marginRight: 0,
      },
    },

    // This section is for Lab components.
    // These are components that have not made it into the styles API as yet.

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    MuiPaginationItem: {
      root: {
        "&$outlined": {
          backgroundColor: palette.common.white,
          borderColor: palette.divider,
          color: palette.text.secondary,
        },
        "&$outlined$disabled": {
          backgroundColor: palette.action.disabledBackground,
          borderColor: palette.divider,
        },

        "&$outlinedPrimary$selected": {
          backgroundColor: palette.common.white,
          borderWidth: "1px",
          color: palette.text.primary,
          borderColor: palette.primary.main,
        },
      },
    },
    MuiPagination: {
      ul: {
        margin: "0 -3px",
      },
    },
  },
});

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    tillGrey: PaletteColor;
  }
  interface PaletteOptions {
    tillGrey: PaletteColor;
  }
}

export default theme;
