import { Box } from "@material-ui/core";
import React from "react";
import Image from "next/image";

import { ECountryIcons } from "types/enums/ECountryIcons";

export enum EIconSize {
  SM = "1rem",
  MD = "1.125rem",
  LG = "1.5rem",
}

export enum EIcon {
  ADD = "/SVG/add_icon.svg",
  ADD_CIRCLED = "/SVG/add_circled_icon.svg",
  AIRPLANE_A_MEDIUM = "/SVG/airplane_a_medium_icon.svg",
  AIRPLANE_B_MEDIUM = "/SVG/airplane_b_medium_icon.svg",
  API_MEDIUM = "/SVG/api_medium_icon.svg",
  APPLICATION_BEEN_SUBMITTED = "/SVG/applicationbeensubmitted_large_icon.svg",
  AUS_FLAG = "/SVG/aus_flag_icon.svg",
  AUTO_A_MEDIUM = "/SVG/auto_a_medium_icon.svg",
  AUTO_B_MEDIUM = "/SVG/auto_b_medium_icon.svg",
  AUTO_C_MEDIUM = "/SVG/auto_c_medium_icon.svg",
  BANK = "/SVG/bank_icon.svg",
  BANK_GRAY = "/SVG/bank_icon_gray.svg",
  BATCH_MEDIUM = "/SVG/batch_medium_icon.svg",
  BENEFICIALOWNER = "/SVG/beneficialowner_icon.svg",
  BNPL_MEDIUM = "/SVG/bnpl_medium_icon.svg",
  BPAY_MEDIUM = "/SVG/bpay_medium_icon.svg",
  BRAIN_MEDIUM = "/SVG/brain_medium_icon.svg",
  BRIEFCASE_REPEAT_MEDIUM = "/SVG/briefcase_repeat_medium_icon.svg",
  BUSINESSINSIGHTS = "/SVG/businessinsights_icon.svg",
  CALENDAR = "/SVG/calendar_icon.svg",
  CASHFUNCTION_MEDIUM = "/SVG/cashfunction_medium_icon.svg",
  CERTIFICATE = "/SVG/certificate_icon.svg",
  CERTIFICATE_GRAY = "/SVG/certificate_icon_gray.svg",
  CHATBUBBLE_MEDIUM = "/SVG/chatbubble_medium_icon.svg",
  CHECKBOX = "/SVG/checkbox_icon.svg",
  CHECKBOX_NOT_VALIDATE = "/SVG/checkbox_icon_not_validate.svg",
  CHECKBOX_DISABLED = "/SVG/checkbox_disabled_icon.svg",
  CHECKBOX_FILLED_DISABLED = "/SVG/checkbox_filled_disabled_icon.svg",
  CHECKBOX_FILLED = "/SVG/checkbox_filled_icon.svg",
  CHEVRON_DOWN = "/SVG/chevron_down.svg",
  CHEVRON_DOWN_GREY = "/SVG/chevron_down_grey.svg",
  CHEVRON_LEFT = "/SVG/chevron_left.svg",
  CHEVRON_LEFT_GREY = "/SVG/chevron_left_grey.svg",
  CHEVRON_RIGHT = "/SVG/chevron_right.svg",
  CHEVRON_RIGHT_GREY = "/SVG/chevron_right_grey.svg",
  CHEVRON_UP = "/SVG/chevron_up.svg",
  CHEVRON_UP_GREY = "/SVG/chevron_up_grey.svg",
  CLOCKWITHTICK_MEDIUM = "/SVG/clockwithtick_medium_icon.svg",
  CLOSE = "/SVG/close_icon.svg",
  COG = "/SVG/cog.svg",
  COGCIRCUIT_MEDIUM = "/SVG/cogcircuit_medium_icon.svg",
  COINWITHARROW_MEDIUM = "/SVG/coinwitharrow_medium_icon.svg",
  COLLAPSELEFT = "/SVG/collapseleft_icon.svg",
  COMPUTERCOG_MEDIUM = "/SVG/computercog_medium_icon.svg",
  CONNECTEDWORLD_MEDIUM = "/SVG/connectedworld_medium_icon.svg",
  COPY = "/SVG/copy_icon.svg",
  CREDITCARD = "/SVG/creditcard_icon.svg",
  CROSS = "/SVG/cross_icon.svg",
  CROSS_RED = "/SVG/cross_red_icon.svg",
  CUSTOMERS = "/SVG/customers_icon.svg",
  DASHBOARD = "/SVG/dashboard_icon.svg",
  DELETE = "/SVG/delete_icon.svg",
  DELETE_RED = "/SVG/delete_icon_red.svg",
  DESKTOP_NOTSELECTED = "/SVG/desktop_notselected_icon.svg",
  DESKTOP_SELECTED = "/SVG/desktop_selected_icon.svg",
  DOB = "/SVG/dob_icon.svg",
  DOB_GRAY = "/SVG/dob_icon_gray.svg",
  DOWNLOAD = "/SVG/download_icon.svg",
  EDIT = "/SVG/edit_icon.svg",
  EDIT_CIRCLED = "/SVG/edit_circled_icon.svg",
  EDIT_INCIRCLE = "/SVG/edit_incircle_icon.svg",
  EMAIL = "/SVG/email_icon.svg",
  EXPAND = "/SVG/expand_icon.svg",
  EXPANDLEFT = "/SVG/expandleft_icon.svg",
  FILTER = "/SVG/filter_icon.svg",
  FORGOTPASS_LARGE = "/SVG/forgotpass_large_icon.svg",
  GENERIC_PHONE = "/SVG/generic_phone_icon.svg",
  GROCERY_MEDIUM = "/SVG/grocery_medium_icon.svg",
  HANDSHAKE_MEDIUM = "/SVG/handshake_medium_icon.svg",
  HELPDESK = "/SVG/helpdesk_icon.svg",
  HIDE_PASSWORD = "/SVG/hidepassword_icon.svg",
  HIDEPASS = "/SVG/hidepass_icon.svg",
  HOSPITALITY_A_MEDIUM = "/SVG/hospitality_a_medium_icon.svg",
  HOSPITALITY_B_MEDIUM = "/SVG/hospitality_b_medium_icon.svg",
  HPP_MEDIUM = "/SVG/hpp_medium_icon.svg",
  INFOBOOK = "/SVG/infobook_icon.svg",
  ITEMS = "/SVG/items_icon.svg",
  KNOWLEDGE_B_MEDIUM = "/SVG/knowledge_b_medium_icon.svg",
  LAST_LOGIN = "/SVG/lastlogin_icon.svg",
  LASTLOGIN = "/SVG/lastlogin_icon.svg",
  LEGACY_GATEWAY = "/SVG/legacyportal_icon.svg",
  LINK = "/SVG/link_icon.svg",
  LINK_LEGEND = "/SVG/link_legend_icon.svg",
  LINKEDACCOUNTS = "/SVG/linkedaccounts_icon.svg",
  LINKSENTTIME_LARGE = "/SVG/linksenttime_large_icon.svg",
  LINKSENTTIME_LARGE_WITHOPACITY = "/SVG/linksenttime_large_withOpacity_icon.svg",
  LOCATION_LEGEND = "/SVG/location_legend_icon.svg",
  LOCATION_LEGEND_GRAY = "/SVG/location_legend_icon_gray.svg",
  LOCATIONS = "/SVG/locations_icon.svg",
  LOGIN_1_MEDIUM = "/SVG/login_1_medium_icon.svg",
  LOGIN_2_MEDIUM = "/SVG/login_2_medium_icon.svg",
  LOGIN_3_MEDIUM = "/SVG/login_3_medium_icon.svg",
  LOGIN_4_MEDIUM = "/SVG/login_4_medium_icon.svg",
  MAINOFFICE_LEGEND = "/SVG/mainoffice_legend_icon.svg",
  MANAGERUSER = "/SVG/manageruser_icon.svg",
  MINUS = "/SVG/minus_icon.svg",
  MOBILE_NOTSELECTED = "/SVG/mobile_notselected_icon.svg",
  MOBILE_NOTSELECTED_GRAY = "/SVG/mobile_notselected_icon_gray.svg",
  MOBILE_SELECTED = "/SVG/mobile_selected_icon.svg",
  MORE_MEDIUM = "/SVG/more_medium_icon.svg",
  MORE_VERTICAL = "/SVG/morevertical_icon.svg",
  MORE_VERTICAL_GREY = "/SVG/morevertical_icon_grey.svg",
  MOTO_MEDIUM = "/SVG/moto_medium_icon.svg",
  NOTIFICATION = "/SVG/notification_icon.svg",
  ONBOARDING_SUCCESS_LARGE = "/SVG/onboardingsuccess_large_icon.svg",
  OPERATIONS = "/SVG/operations_icon.svg",
  PAPERPLANE_A = "/SVG/paperplane_a_icon.svg",
  PAPERPLANE_A_MEDIUM = "/SVG/paperplane_a_medium_icon.svg",
  PAPERPLANE_B = "/SVG/paperplane_b_icon.svg",
  PAPERPLANE_B_MEDIUM = "/SVG/paperplane_b_medium_icon.svg",
  PASSWORD = "/SVG/password_icon.svg",
  PASSWORD_LOCK = "/SVG/password_lock_icon.svg",
  PBL_LOGIN = "/SVG/pbl_login_icon.svg",
  PBL_MEDIUM = "/SVG/pbl_medium_icon.svg",
  PEOPLE_A_COG = "/SVG/people_a_cog_icon.svg",
  PEOPLE_A_MEDIUM = "/SVG/people_a_medium_icon.svg",
  PEOPLE_B_MEDIUM = "/SVG/people_b_medium_icon.svg",
  PEOPLE_COG_B = "/SVG/people_cog_b_icon.svg",
  PHONE_CIRCLED = "/SVG/phone_circled_icon.svg",
  PLUS = "/SVG/plus_icon.svg",
  POS = "/SVG/pos_icon.svg",
  POSTERMINAL_MEDIUM = "/SVG/posterminal_medium_icon.svg",
  RADIOBUTTON = "/SVG/radiobutton_icon.svg",
  RADIOBUTTON_FILL = "/SVG/radiobutton_fill_icon.svg",
  RESPONSIVE_MENU_ICON = "/SVG/responsive_menu_icon.svg",
  RECURRING_MEDIUM = "/SVG/recurring_medium_icon.svg",
  RETRY = "/SVG/retry_icon.svg",
  RETRY_ORANGE = "/SVG/retry_orange_icon.svg",
  RIGHTCHANGEARROW = "/SVG/rightchangearrow_icon.svg",
  RISKMANAGEMENT = "/SVG/riskmanagement_icon.svg",
  ROCKET_MEDIUM = "/SVG/rocket_medium_icon.svg",
  SEARCH = "/SVG/search_icon.svg",
  SETTING = "/SVG/setting_icon.svg",
  SETTLEMENTS = "/SVG/settlements_icon.svg",
  SHOPPING_BAG = "/SVG/shoppingbag_icon.svg",
  SHOW_PASSWORD = "/SVG/showpassword_icon.svg",
  SHOWPASS = "/SVG/showpass_icon.svg",
  SMALL_CROSS = "/SVG/small_cross_icon.svg",
  SMILEY_A = "/SVG/smiley_a_icon.svg",
  SMILEY_A_MEDIUM = "/SVG/smiley_a_medium_icon.svg",
  SMILEY_B = "/SVG/smiley_b_icon.svg",
  SMILEY_B_MEDIUM = "/SVG/smiley_b_medium_icon.svg",
  STAIRSWITHARROW_MEDIUM = "/SVG/stairswitharrow_medium_icon.svg",
  STORE = "/SVG/store_icon.svg",
  STORE_GRAY = "/SVG/store_icon_gray.svg",
  TICK_GREEN = "/SVG/tick_green_icon.svg",
  TICK_SUCCESS = "/SVG/tick_success_icon.svg",
  TICK_SUCCESS_IN_CIRCLE = "/SVG/successsubmit_medium_icon.svg",
  TILL_LOGO = "/SVG/till_logo.svg",
  TILL_MEDIUM = "/SVG/till_medium_icon.svg",
  TILL_SHIELD = "/SVG/till_shield_icon.svg",
  TILL_VISION_LOGIN = "/SVG/tillvision_login_icon.svg",
  TIME = "/SVG/time_icon.svg",
  TOOLTIP = "/SVG/tooltip_notselected_icon.svg",
  TOOLTIP_SELECTED = "/SVG/tooltip_selected_icon.svg",
  TRANSACTION = "/SVG/transaction_icon.svg",
  TWO_FA = "/SVG/2fa_icon.svg",
  UPLOAD = "/SVG/upload_icon.svg",
  USER = "/SVG/user_profile.svg",
  USER_LARGE = "/SVG/user_profile_large.svg",
  USER_GREY = "/SVG/user_profile_grey.svg",
  USER_OUTLINE = "/SVG/user_outline_icon.svg",
  WALLET_MEDIUM = "/SVG/wallet_medium_icon.svg",
  WARNING = "/SVG/warning_icon.svg",
  WARNING_YELLOW = "/SVG/warning_yellow_icon.svg",
  WEBSITE = "/SVG/website_icon.svg",
  WEBSITE_BUSINESS = "/SVG/website_business_icon.svg",
}

export interface IIconProps {
  size?: EIconSize;
  icon: EIcon | ECountryIcons;
  alt?: string;
}

export function Icon({
  size = EIconSize.MD,
  icon,
  alt = "",
}: IIconProps): JSX.Element {
  return (
    <Box height={size} width={size}>
      <Image
        layout="responsive"
        src={icon}
        alt={alt}
        height="100%"
        width="100%"
      />
    </Box>
  );
}
