import Head from "next/head";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import React, { useEffect, useRef, useState } from "react";
import type { AppProps } from "next/app";
import { QueryClient, QueryClientProvider } from "react-query";
import { Hydrate } from "react-query/hydration";
import { ReactQueryDevtools } from "react-query/devtools";
import "react-datepicker/dist/react-datepicker.css";

import { AccessProvider } from "src/contexts/Access";
import { CurrentUserProvider } from "src/contexts/CurrentUser";
import theme from "src/theme";
import { buildQueryClient } from "src/utilities/buildQueryClient";
import { getLayout } from "src/components/Layout/Section/Layouts";
import { getMainLayout } from "src/components/Layout/Page/EMainLayout";
import { GoogleAnalitics } from "src/components/GoogleAnalytics";

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  const [accessToken, setAccessToken] = useState(pageProps.accessToken);

  useEffect(() => {
    setAccessToken(pageProps.accessToken);
  }, [pageProps.accessToken]);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  const queryClientRef = useRef<QueryClient>();

  if (!queryClientRef.current) {
    queryClientRef.current = buildQueryClient({
      accessToken: pageProps.accessToken,
    });
  }

  useEffect(() => {
    queryClientRef.current = buildQueryClient({
      accessToken: pageProps.accessToken,
    });
  }, [pageProps.accessToken]);

  const SectionLayout = getLayout(pageProps.layout);

  // Quick and dirty fix for MVP
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const MainLayout: any = getMainLayout(pageProps.mainLayout);

  return (
    <>
      <Head>
        <title>Till - NextGen</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <GoogleAnalitics />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <QueryClientProvider client={queryClientRef.current}>
          <Hydrate state={pageProps.dehydratedState}>
            <AccessProvider
              accessToken={accessToken}
              setAccessToken={setAccessToken}
            >
              <CurrentUserProvider currentUser={pageProps.user}>
                <MainLayout
                  title={pageProps.title}
                  breadcrumbs={pageProps.breadcrumbs}
                  withBackButton={pageProps.withBackButton}
                  withFloatingBar={pageProps.withFloatingBar}
                  merchantApplication={pageProps.merchantApplication}
                >
                  <SectionLayout {...pageProps.subLayoutProps}>
                    <Component {...pageProps} />
                  </SectionLayout>
                </MainLayout>
              </CurrentUserProvider>
            </AccessProvider>
          </Hydrate>
          <ReactQueryDevtools position="bottom-right" />
        </QueryClientProvider>
      </ThemeProvider>
    </>
  );
}

export default MyApp;
