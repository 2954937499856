import { createContext, ReactNode } from "react";

import { ICurrentUser } from "types/gateway";

interface ICurrentUserContext {
  currentUser?: ICurrentUser;
}

interface IUsePermissionsState {
  hasPermission: (permission: string) => boolean;
}

export const CurrentUserContext = createContext<ICurrentUserContext>({
  currentUser: undefined,
});

interface IAccessProviderProps {
  currentUser: ICurrentUser;
  children: ReactNode;
}

export function CurrentUserProvider({
  currentUser,
  children,
}: IAccessProviderProps): JSX.Element {
  return (
    <CurrentUserContext.Provider value={{ currentUser }}>
      {children}
    </CurrentUserContext.Provider>
  );
}

export function useCurrentUser(): ICurrentUser {
  return { roles: [] } as unknown as ICurrentUser;
}

export function usePermissions(): IUsePermissionsState {
  return { hasPermission: () => true };
}
