import loadable from "@loadable/component";
import { LoadableComponent } from "next/dynamic";

const PortalLayout = loadable(() => import("./Portal"));
const PublicLayout = loadable(() => import("./Public"));

export enum EMainLayout {
  Portal,
  Public,
}

export function getMainLayout(
  layout: EMainLayout
): LoadableComponent<unknown> | "div" {
  switch (layout) {
    case EMainLayout.Public:
      return PublicLayout as LoadableComponent<unknown>;
    case EMainLayout.Portal:
    default:
      return PortalLayout as LoadableComponent<unknown>;
  }
}
