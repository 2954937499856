import { QueryClient } from "react-query";

import { defaultQueryFn } from "src/utilities/defaultQueryFn";

export function buildQueryClient(
  params: {
    accessToken?: string;
    responseCb?: (response: Response) => void;
    headers?: Record<string, string>;
  } = {}
): QueryClient {
  return new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: defaultQueryFn(params),
      },
    },
  });
}
